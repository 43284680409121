import React from "react";

import MarkdownViewer from "../../../components/merged/MarkdownViewer";
import { ExtraLargeTitle } from "../../../components/shared/FontCollection"
import "./Info.css";

export default function Info({ title, description }) {
    return <section className="info">
        <div className="info__content">
            <div className="info__title">
                <ExtraLargeTitle>{title}</ExtraLargeTitle>
            </div>
            <div className="info__description">
                <MarkdownViewer data={description} />
            </div>
        </div>
    </section>
}