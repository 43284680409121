import * as React from "react"
import { graphql } from "gatsby";

import Seo from "../components/shell/seo"
import LayoutWrapper from "../template/LayoutWrapper";
import AboutUsContent from "../pageContents/aboutUs/AboutUsContent";
import { getSeoData } from "../services/seo.service";

function AboutUsPage(props) {
  return <LayoutWrapper pageContext={props.pageContext}>
    <Seo {...getSeoData(props, props.data.aboutUsJson)} />
    <AboutUsContent aboutUsData={props.data.aboutUsJson} />
  </LayoutWrapper>
}

export default AboutUsPage;

export const query = graphql`
  {
    aboutUsJson {
      seoTranslations {
        language
        title
        keywords
        description
      }
      translations {
        language
        title
        description
      }
    }
  }
`